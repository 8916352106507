import React from "react"
import Layout from "../components/layout"
import SidebarPostInfo from "../components/SidebarPostInfo"
import Comments from "../components/Comments"
import {
  CloudinaryMediaCarousel,
} from "../components/CloudinaryMedia"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
// Material UI Kit components
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
import Card from "../components/Card/Card"
import CardBody from "../components/Card/CardBody"
import carouselStyle from "../assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.jsx"
import imagesStyles from "../assets/jss/material-kit-pro-react/imagesStyles"

const style = {
  ...carouselStyle,
  ...withStyles,
  ...imagesStyles,
  fullWidth: {
    width: "100%",
    height: "auto",
  },
  carousel: {
    borderRadius: "6px",
    overflow: "hidden",
  },
}

function PostPlain({ ...props }) {
  const isMobile = useMediaQuery("(max-width:768px)")
  const {
    pageContext: {
      pageData: {
        avatar,
        created,
        body,
        drupalId,
        type,
        fieldImage,
        fieldLike,
        relationships,
        drupalInternalNid,
      },
    },
  } = props

  const sidebar = () => (
    <SidebarPostInfo
      postId={drupalInternalNid}
      uuid={drupalId}
      type={type}
      created={created}
      authorName={relationships.uid.name}
      authorImage={avatar}
      fieldLike={fieldLike}
    />
  )

  return (
    <Layout {...props}>
      <div style={style.root}>
        <GridContainer
          id={"sidebar"}
          spacing={3}
          direction="row"
          justify="center"
          alignContent="center"
        >
          {!isMobile && (
            <GridItem xs={12} md={2}>
              {sidebar()}
            </GridItem>
          )}
          <GridItem id={"card-wrapper"} xs={12} md={10}>
            <Card
              id={"card"}
              style={style.carousel}
              xs={12}
              sm={12}
              md={8}
              lg={8}
            >
              <div id={"carousel-wrapper"}>
                <CloudinaryMediaCarousel
                  imageArray={fieldImage}
                  transformations={
                    isMobile
                      ? "q_auto,f_auto,dpr_2.0,w_1024,h_576,c_limit"
                      : "q_auto,f_auto,dpr_2.0,w_1024,h_576,c_pad"
                  }
                  progressive={
                    isMobile ? "w_1024,h_576,c_limit" : "w_1024,h_576,c_pad"
                  }
                />

                {isMobile && sidebar()}
              </div>
              <CardBody>
                <div style={style.fullWidth}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: body,
                    }}
                  />
                </div>
              </CardBody>
            </Card>
            <div style={{ padding: "10px" }}>
              <Comments id={drupalId} type={type} />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Layout>
  )
}

export default PostPlain
