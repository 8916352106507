import React from "react"
import PropTypes from "prop-types"
import Likes from "../Likes"
import TimeAgo from "react-timeago"
import FitText from "@kennethormandy/react-fittext"
import { CloudinaryMediaSingle } from "../CloudinaryMedia"
// Material UI Core
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
// Material UI Kit
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import Card from "../Card/Card"
import Grid from "@material-ui/core/Grid"

const style = {
  root: {
    flexGrow: 1,
  },
  imgFullWidth: {
    width: "75%",
    height: "auto",
    margin: "20px",
  },
  imgFullWidthMobile: {
    width: "75%",
    height: "auto",
  },
  name: {
    fontWeight: "800",
    fontSize: "30px",
    padding: "0 !important",
  },
  card: {
    textAlign: "center",
    display: "block",
    padding: "0 20px 20px 20px",
  },
}

function SidebarPostInfo({ ...props }) {
  const isMobile = useMediaQuery("(max-width:768px)")
  const {
    uuid,
    postId,
    type,
    created,
    authorImage,
    authorName,
    fieldLike,
  } = props

  const image =
    authorImage === "undefined" ? "defaults/default_avatar" : authorImage
  const fieldImage = { pid: image, resourceType: "image" }
  return (
    <div style={isMobile ? style.root : { ...style.root, marginTop: "8px" }}>
      <GridContainer
        spacing={2}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Card style={style.card} plain={isMobile} xs={12}>
          <GridContainer
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <GridItem md={12} xs={3}>
              <div
                style={isMobile ? style.imgFullWidthMobile : style.imgFullWidth}
              >
                <CloudinaryMediaSingle
                  image={fieldImage}
                  transformations={"q_auto,f_auto,dpr_auto,w_160,c_scale"}
                  alt={authorName}
                />
              </div>
            </GridItem>
            <GridItem
              style={style.name}
              md={12}
              xs={isMobile ? 6 : 3}
              className={"cardWrapperNoPadding"}
            >
              <FitText maxFontSize={13}>{authorName}</FitText>
              {isMobile && (
                <FitText>
                  <TimeAgo style={{ fontSize: "10px" }} date={created} />
                </FitText>
              )}
            </GridItem>
            {!isMobile && (
              <Grid item md={12} xs={3} className={"cardWrapperNoPadding"}>
                <TimeAgo date={created} />
              </Grid>
            )}
            <GridItem md={12} xs={3} className={"cardWrapperNoPadding"}>
              <Likes
                id={postId}
                uuid={uuid}
                type={type}
                field_like={fieldLike}
                clear={!isMobile}
                getLiveLikes={true}
              />
            </GridItem>
          </GridContainer>
        </Card>
      </GridContainer>
    </div>
  )
}

export default SidebarPostInfo

SidebarPostInfo.propTypes = {
  uuid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fieldLike: PropTypes.object,
}
